import React from 'react'
import { graphql } from 'gatsby'

import { PageLinkExternal } from '../styled/PageLink'
import { BylineAuthor } from '../generic/BylineAuthor'
import { BylineDate } from '../generic/BylineDate'
import { ItemEntry } from './ItemEntry'
import { PostCategoryList } from './PostCategoryList'

export function ProjectItem({ item: project, isSingle }) {
  const title = project.title || project.slug.current

  const byline = (
    <>
      <span>
        Started <BylineDate date={project.creationDate} />
      </span>
      {project.authors.map(author => {
        return <BylineAuthor author={author} hidden key={author.name} />
      })}
    </>
  )

  return (
    <ItemEntry
      isSingle={isSingle}
      title={title}
      slug={'/projects/' + project.slug.current}
      byline={byline}
      excerpt={project.excerpt}
      content={project.description}
      afterContent={
        isSingle ? (
          <>
            {project.url && (
              <p>
                <PageLinkExternal href={project.url}>
                  Visit project
                </PageLinkExternal>
              </p>
            )}
            <p>
              Using{' '}
              {project.technologies.reduce((text, tech, i, array) => {
                text += tech.title
                if (i === array.length - 2) {
                  text += ' and '
                } else if (i < array.length - 1) {
                  text += ', '
                }
                return text
              }, '')}
            </p>
          </>
        ) : (
          <PostCategoryList categories={project.technologies} />
        )
      }
      extraLink={
        !isSingle &&
        project.url && (
          <PageLinkExternal href={project.url}>Visit project</PageLinkExternal>
        )
      }
    />
  )
}

export const query = graphql`
  fragment ProjectItem on SanityProject {
    title
    slug {
      current
    }
    creationDate
    authors {
      name
      website
    }
    excerpt
    description: _rawDescription
    url
    technologies {
      title
    }
  }
`

import React from 'react'
import { graphql } from 'gatsby'

import { Bio } from '../components/layout/Bio'
import { Layout } from '../components/layout/Layout'
import { SEO } from '../components/seo'
import { HR } from '../components/styled/HR'
import { postExcerpt } from '../utils/postExcerpt'
import { ProjectItem } from '../components/page/ProjectItem'

function ProjectPageTemplate({ data: { project } }) {
  return (
    <Layout>
      <SEO
        title={project.title}
        description={postExcerpt(project.excerpt, project.description)}
        image={`/og-images/projects/${project.slug.current}.png`}
      />
      <ProjectItem item={project} isSingle />
      <HR />
      {project.authors.map(author => {
        return <Bio author={author} key={author.name} />
      })}
    </Layout>
  )
}

export default ProjectPageTemplate

export const pageQuery = graphql`
  query ProjectPageTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      ...ProjectItem
      authors {
        ...AuthorBio
      }
    }
  }
`
